import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Seo from '../components/seo/seo'
//import Layout from '../layout/layout'

const MainForPages = styled.main`
	max-width: 42rem;
	margin: 1rem auto;
	padding: 20px;
	grid-template-columns: 1fr;
`

const About = ({location}) => (
//  <Layout location={location}>
	<MainForPages>
		<Seo title="Chi sono - About - anonimoconiglio.com" description="chi è anonimoconiglio? pagina About" slug="/about" />
    <div>
      <h1>Cos'è anonimoconiglio?</h1>
			<p>
				Ciao. Mi chiamo Santiago. Sono nato in Argentina e ora vivo in Italia, sufficiente per dire che la mia vita va di male in peggio. Dopo essermi laureato in Mediazione Interlinguistica Culturale ho fatto una magistrale in Mass Media e Politica. Voi dirette "mecojoni", e invece oggi lavoro in tutt'altro settore. 
			</p>
			<p>
				Fallimento dietro fallimento ho creato questo blog nel 2010 e da allora scrivo cose che dovrebbero essere satiriche ma quasi mai ci riescono, pezzi che dovrebbero parlare di Software Libero ma parlano di tutt'altro e ogni tanto qualche considerazione politica.
			</p>
			<p>
			 Col passare del tempo mi sono reso conto del motivo fondamentale per cui esiste questo posto: lo psicologo mi costerebbe troppo.
			</p>
			<p>
				Penso che ulteriori dati anagrafici siano inutili. Le foto personali ve le risparmio. Però, ecco, potete leggere quello che scrivo, magari vi tira fuori una risata, una riflessione o una imprecazione.
			</p>
			<p>
				Preferisco le imprecazioni, di gran lunga, le trovo più stimolanti.
			</p>
			<p>
				Benvenuti in questa Tana, dove l'entrata e gratis, ma all'uscita ne riparliamo.
			</p>
			<ul>
				<li>Mi raccomando qui trovate le <Link to="p/questo-blog-e-un-luogononsense">informazioni legali - Disclaimer del blog</Link>.</li>
				<li>Per contattarmi in privato potete usare twitter o pazientare finché non crei un form di contatto.</li>
			</ul>
		</div>
	</MainForPages>
// </Layout>
)

export default About
